@import '@quno/patient-journey/styles/breakpoints.scss';

/* stylelint-disable */
.calendar {
  background-color: var(--color-white);
  max-width: 100% !important;
  padding-bottom: var(--spacing-2-5);

  h2,
  h3 {
    color: var(--color-black);
  }

  :global(.DTP__details) {
    display: none !important;
  }

  :global(.DTP__calendar-grid--button) {
    padding: var(--spacing-2-5) !important;
    border: 0 !important;
  }

  :global(.DTP__calendar-grid--selected:disabled) {
    color: var(--color-black);
  }

  :global(.DTP__calendar-header-button:hover) {
    box-shadow: unset !important;
  }

  :global(.DTP__calendar-header-button--icon) {
    background-color: var(--color-black);
    fill: var(--color-white) !important;
    width: var(--spacing-8) !important;
    height: var(--spacing-8) !important;
    padding: 9px;
    border-radius: 50%;
  }

  :global(.DTP__column--right) {
    margin-top: var(--spacing-4);

    @include breakpoints('desktop') {
      margin-top: 0;
    }
  }

  :global(.DTP__confirm-button.confirm-button-loading) {
    background-color: var(--theme-background-color-dark) !important;
    display: flex !important;
    align-items: center;
    justify-content: center;
    z-index: 1000 !important;
    cursor: not-allowed !important;
    pointer-events: none;

    > div {
      width: var(--spacing-5) !important;
      height: var(--spacing-5) !important;
    }

    svg circle {
      stroke: var(--color-white) !important;
    }

    & + button {
      display: none !important;
    }
  }

  :global(.DTP__time-slot) {
    background-color: var(--theme-primary-color) !important;
    color: white !important;

    &:hover {
      background-color: var(--theme-background-color-dark) !important;

      & span {
        color: white !important;
      }
    }
  }
}
/* stylelint-enable */
