@import '@quno/patient-journey/styles/breakpoints.scss';

.imageWrapper {
  display: flex;
  justify-content: flex-start;
  width: 100%;
  padding: var(--spacing-2) 0;

  > img {
    height: var(--spacing-9);
    width: auto;
  }
}

.item {
  min-width: 300px;
  padding: var(--spacing-4) var(--spacing-2);

  p {
    line-height: 27px;
    font-size: var(--text-lg-size);
  }
}

.title {
  color: var(--theme-dark-gray);
  margin-bottom: var(--spacing-4);
  margin-top: var(--spacing-2);

  @include breakpoints('desktop') {
    margin-block: var(--spacing-4);
  }
}
