@import '@quno/patient-journey/styles/breakpoints.scss';

.card {
  justify-content: center !important;
  min-width: fit-content;
  border-color: transparent !important;
  min-width: 300px;

  > div {
    padding: 0;
    padding-inline: var(--theme-spacing-1);
  }

  @include breakpoints('desktop') {
    > div {
      padding-inline: var(--theme-spacing-3);
    }
  }

  & img {
    height: 60px;
    object-fit: contain;
  }
}
