@import '@quno/patient-journey/styles/spacings.scss';
@import '@quno/patient-journey/styles/breakpoints.scss';

.wrapper {
  width: 100%;
  overflow: auto;
  padding-right: var(--spacing-2-5);
  color: var(--theme-dark-gray);

  &::-webkit-scrollbar {
    width: var(--spacing-2-5);
    height: var(--spacing-2-5);
  }

  &::-webkit-scrollbar-track {
    background-color: var(--theme-light-gray);
    border-radius: var(--spacing-2);
  }

  &::-webkit-scrollbar-thumb {
    background-color: #00bcb3;
    border-radius: var(--spacing-2);
  }

  .information {
    border: 1px solid var(--theme-light-gray);
    background: #f8f7f7;
    border-radius: var(--spacing-2);
    width: 100%;
    text-align: center;

    p {
      font-size: var(--text-sm-size) !important;
      min-width: 100%;
    }

    b {
      font-size: var(--text-base-size);
      font-weight: 700;
    }

    .name {
      font-size: var(--text-lg-size);
    }

    .address {
      color: var(--theme-medium-gray);
    }

    .duration {
      display: flex;
      border-bottom: 1px solid var(--theme-light-gray);
      padding-block: $theme-spacing-1;

      .separator {
        width: 1px;
        background-color: var(--theme-light-gray);
      }

      .item {
        flex: 1;
        display: flex;
        align-items: center;
        justify-content: center;
        gap: var(--spacing-2);

        span {
          width: 100%;
        }
      }

      .item:first-of-type {
        padding-left: $theme-spacing-2;
        padding-right: $theme-spacing-1;
        white-space: nowrap;
      }

      .item:last-of-type {
        padding-right: $theme-spacing-2;
        padding-left: $theme-spacing-1;
        white-space: nowrap;
      }
    }

    .clinic {
      display: flex;
      flex-direction: column;
      padding-block: var(--spacing-2-5);
      gap: var(--spacing-2-5);
      padding-inline: $theme-spacing-2;
    }
  }

  .doctors {
    display: flex;
    flex-direction: column;
    gap: var(--spacing-2-5);
    margin-top: var(--spacing-2-5);

    .skeletonContainer {
      display: flex;
      flex-direction: column;
      gap: var(--spacing-2-5);

      .skeletonWrapper {
        display: flex;
        align-items: center;
        gap: var(--spacing-4);
        border: 1px solid #e6e6e6;
        background-color: #fff;
        padding: var(--spacing-2) var(--spacing-3);
        border-radius: var(--spacing-2);

        .photo {
          width: var(--spacing-12-5);
          height: var(--spacing-12-5);
          border-radius: 100%;
        }

        .info {
          display: flex;
          flex-direction: column;
          gap: var(--spacing-1);

          .nameSkeleton {
            width: var(--spacing-50);
            height: var(--spacing-5-5);
          }

          .titleSkeleton {
            width: var(--spacing-50);
            height: var(--spacing-4);
          }
        }
      }
    }

    .doctor {
      display: flex;
      gap: var(--spacing-4);
      padding: var(--spacing-2) var(--spacing-3);
      border: 1px solid #e6e6e6;
      background-color: #fff;
      border-radius: var(--spacing-2);
      cursor: pointer;

      &.selected {
        border: 1px solid #00bcb3;
        background-color: #eef9f7;
      }

      img {
        border-radius: 50%;
        margin: auto;
        width: var(--spacing-12-5);
        height: var(--spacing-12-5);
        object-fit: cover;
        object-position: top;
      }

      .info {
        display: flex;
        flex-direction: column;
        line-height: normal;
        justify-content: center;

        .name {
          color: var(--theme-dark-gray);
          font-weight: 700;
          font-size: var(--text-lg-size);
        }

        .title {
          color: var(--theme-dark-gray);
          font-weight: 400;
          font-size: var(--text-base-size);
        }
      }
    }
  }
}

@include breakpoints('desktop') {
  .wrapper {
    max-width: var(--spacing-80);
  }
}
