@import '@quno/patient-journey/styles/breakpoints.scss';

.item {
  box-shadow: 0 0 2px #aaa;
  min-width: 300px;

  &.icon {
    .imageWrapper {
      padding: var(--spacing-2) 0;

      > img {
        height: var(--spacing-9);
        width: auto;
      }
    }
  }

  &.circle {
    .imageWrapper {
      border-radius: 50%;
      overflow: hidden;
      margin-bottom: var(--spacing-4);

      & img {
        height: 100px;
        width: 100px;
        object-fit: cover;
      }
    }
  }

  &.full {
    & > div {
      padding: 0;
    }

    & .text {
      padding: var(--spacing-4);
    }

    .imageWrapper {
      // border-radius: 50%;
      overflow: hidden;
      margin-bottom: var(--spacing-4);
      width: 100%;

      & img {
        width: 100%;
        height: 100px;
        object-fit: cover;
      }
    }
  }

  h4 {
    margin-block: var(--spacing-4);
  }
}
