.datepicker {
  position: relative;
  z-index: 10;
}

.hiddenSlots {
  :global(.DTP__slot-list--item) {
    display: none;
  }

  :global(.DTP__slot-list--item:first-of-type) {
    display: list-item;

    :global(span:first-of-type) {
      clip: unset;
      clip-path: unset;
      height: unset;
      overflow: unset;
      position: unset;
      white-space: unset;
    }

    :global(span:last-of-type) {
      display: none;
    }
  }
}
