@import '@quno/patient-journey/styles/fontSizes.scss';

.main {
  container-type: inline-size;
}

.minHeight {
  min-height: 100vh;
}

.root {
  font-family: var(--theme-text-font-family);

  h1,
  h2,
  h3,
  h4,
  p {
    font-family: var(--font-inter);
    padding: 0;
  }

  h1,
  h2,
  h3,
  h4,
  button {
    font-weight: 700;
  }

  h1 {
    font-size: $theme-font-h1;
    font-family: var(--theme-h1-font-family);
  }

  h2 {
    font-size: $theme-font-h2;
    font-family: var(--theme-h2-font-family);
  }

  h3 {
    font-size: $theme-font-h3;
    font-family: var(--theme-h3-font-family);
  }

  h4 {
    font-size: $theme-font-h4;
    font-family: var(--theme-h4-font-family);
  }

  h5 {
    font-size: $theme-font-h5;
    font-family: var(--theme-h5-font-family);
  }

  h6 {
    font-size: $theme-font-h6;
    font-family: var(--theme-h6-font-family);
  }

  button {
    font-size: $theme-font-button;
    font-family: var(--theme-button-font-family);
  }

  p {
    font-size: $theme-font-p;
    font-family: var(--theme-text-font-family);
  }

  a {
    font-family: var(--theme-text-font-family);
  }
}
