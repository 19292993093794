@import '@quno/patient-journey/styles/breakpoints.scss';

.imageWrapper {
  border-radius: 50%;
  overflow: hidden;
  margin-bottom: var(--spacing-4);

  & img {
    height: 100px;
    width: 100px;
    object-fit: cover;
  }
}

.item {
  box-shadow: 0 0 2px #aaa;
  min-width: 300px;

  h4 {
    margin-block: var(--spacing-4);
  }
}
