@import '@quno/patient-journey/styles/spacings.scss';
@import '@quno/patient-journey/styles/breakpoints.scss';

.container {
  display: flex;
  gap: $theme-spacing-2;
  flex: 1;
  width: 100%;
  flex-flow: row wrap;
  position: absolute;
}

.slot,
.calendar {
  width: 100%;
}

.calendar {
  h2 {
    line-height: 1;
    margin-bottom: var(--spacing-4);
    padding: 0;
  }
}

.slot {
  h2,
  h2 span {
    line-height: 1;
    margin-bottom: var(--spacing-2-5);
    padding: 0;
  }
}

@include breakpoints('tablet') {
  .slot {
    display: none;
  }
}

@include breakpoints('desktop') {
  .container {
    flex-wrap: nowrap;
  }
}
