@import '@quno/patient-journey/styles/variables.scss';

.container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100vh;
  gap: 24px;

  .description {
    color: #19272e;
    font-size: 16px;
  }

  .headline {
    color: #19272e;
    font-size: 22px;

    strong {
      font-weight: 700;
    }
  }

  .button {
    width: max-content;
    font-family: var(--fontFamily-inter);
    font-size: 16px;
    color: #0b3142;
    align-self: flex-end;
  }

  @media (min-width: $screens-lg) {
    height: unset;
    padding-inline: 0;
  }
}
