@import '@quno/patient-journey/styles/breakpoints.scss';
@import '@quno/patient-journey/styles/variables.scss';

.wrapper {
  width: 100%;
  min-width: 300px;

  .imageWrapper {
    width: 100%;

    & img {
      height: 100%;
      width: 100%;
      object-fit: cover;
      aspect-ratio: 16 / 9;

      @include breakpoints('desktop', 'tablet') {
        height: 100%;
        width: 100%;
        object-fit: cover;
        aspect-ratio: unset;
      }
    }
  }
}
