$theme-spacing-1: var(--theme-spacing-1);
$theme-spacing-2: var(--theme-spacing-2);
$theme-spacing-3: var(--theme-spacing-3);
$theme-spacing-4: var(--theme-spacing-4);
$theme-spacing-5: var(--theme-spacing-5);
$theme-spacing-6: var(--theme-spacing-6);
$theme-spacing-7: var(--theme-spacing-7);
$theme-spacing-8: var(--theme-spacing-8);
$theme-spacing-9: var(--theme-spacing-9);
$theme-spacing-10: var(--theme-spacing-10);
