@import '@quno/patient-journey/styles/spacings.scss';

.container {
  display: flex;
  flex-direction: column;
  gap: $theme-spacing-1;

  h4 {
    color: var(--color-qunoRed);
  }
}
