$breakpoints-config: (
  'phone': 'width < 767px',
  'tablet': '1023px > width > 768px',
  'desktop': 'width > 1024px',
);

@mixin breakpoints($args...) {
  @each $arg in $args {
    @if map-has-key($breakpoints-config, $arg) {
      @container (#{map-get($breakpoints-config, $arg)}) {
        @content;
      }
    }
  }
}

$media-breakpoints-config: (
  'phone': 'only screen and (max-width: 767px)',
  'tablet': 'only screen and (min-width: 768px) and (max-width: 1023px)',
  'desktop': 'only screen and (min-width: 1024px)',
);

@mixin media-breakpoints($args...) {
  @each $arg in $args {
    @if map-has-key($media-breakpoints-config, $arg) {
      @media #{map-get($media-breakpoints-config, $arg)} {
        @content;
      }
    }
  }
}

$container-max-width: 1600px;
