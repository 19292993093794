/*! purgecss ignore */
.card {
  border: 1px solid #cacaca;
  border-radius: 8px;
  display: grid;
  overflow: hidden;
  position: relative;

  &:not(.card-full-image) {
    display: flex;
    justify-content: space-between;
  }

  &-content {
    display: flex;
    grid-column-start: 1;
    grid-row-start: 1;
    padding: {
      left: 1rem;
      right: 1rem;
      inline: 1rem;
      top: 0.75rem;
      bottom: 0.75rem;
      block: 0.75rem;
    }

    width: 100%;
    z-index: 20;
  }

  &-footer {
    display: flex;
    grid-column-start: 1;
    grid-row-start: 1;
    padding-block: 0.75rem;
    width: 100%;
    z-index: 20;
  }

  &-horizontal {
    flex-direction: row;

    & .card-content {
      flex-direction: row;
    }
  }

  &-responsive {
    flex-direction: column;

    & .card-content {
      flex-direction: column;
    }

    @media (min-width: 1024px) {
      flex-direction: row;

      & .card-content {
        flex-direction: row;
      }
    }
  }

  &-vertical {
    flex-direction: column;

    & .card-content {
      flex-direction: column;
    }
  }

  &-media {
    overflow: hidden;
    position: relative;

    figure {
      height: 100%;
      margin: 0;
    }

    img {
      height: auto;
      object-fit: contain;
      width: auto;
    }

    &-content {
      padding: 0.5rem;
      position: absolute;

      &-tl {
        top: 0;
        left: 0;
      }

      &-tr {
        top: 0;
        right: 0;
      }

      &-tc {
        top: 0;
        left: 50%;
        transform: translateX(-50%);
      }

      &-bl {
        bottom: 0;
        left: 0;
      }

      &-br {
        bottom: 0;
        right: 0;
      }

      &-bc {
        bottom: 0;
        left: 50%;
        transform: translateX(-50%);
      }

      &-c {
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }

      &-cl {
        top: 50%;
        left: 0;
        transform: translateY(-50%);
      }

      &-cr {
        top: 50%;
        right: 0;
        transform: translateY(-50%);
      }
    }
  }

  &-footer {
    display: flex;
    grid-column-start: 1;
    grid-row-start: 1;
    padding-block: 0.75rem;
    width: 100%;
    z-index: 20;
  }

  &-start {
    align-items: flex-start;
  }

  &-center {
    align-items: center;
  }

  &-end {
    align-items: flex-end;
  }

  &-full-image {
    grid-column-start: 1;
    grid-row-start: 1;
  }

  &-opacity::before {
    background-color: rgb(42, 46, 55);
    bottom: 0;
    content: ' ';
    opacity: 75%;
    position: absolute;
    top: 0;
    width: 100%;
  }

  &-cover {
    img {
      height: 100%;
      object-fit: cover;
      width: 100%;
    }
  }

  &-hoverable:hover {
    box-shadow: 0 4px 10px rgba(25, 1, 52, 0.16);
  }
}
