.imageWrapper {
  margin: calc(var(--spacing-4) * -1) calc(var(--spacing-4) * -1)
    var(--spacing-4);
  margin-top: var(--spacing-2);

  & img {
    aspect-ratio: 1.7;
    max-height: 360px;
    object-fit: cover;
  }
}

.item {
  box-shadow: 0 0 2px #aaa;
  min-width: 300px;
}
