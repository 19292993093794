@import '@quno/patient-journey/styles/breakpoints.scss';
@import '@quno/patient-journey/styles/spacings.scss';

.header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: $theme-spacing-2 $theme-spacing-4;
  max-width: $container-max-width;
  margin: auto;
  container-type: inline-size;

  .logo {
    position: relative;

    img {
      position: relative !important;
      height: var(--spacing-10) !important;
      width: auto !important;
      object-fit: contain;
    }
  }

  a {
    text-decoration: none;
  }

  .contactUs {
    display: none;
    color: var(--theme-dark-gray);

    .contactUsPhoneNumber strong {
      font-size: var(--text-xl-size);
    }

    p {
      line-height: 1;
    }

    .contactUsBlock {
      display: flex;
      flex-direction: column;
      gap: var(--spacing-1);
    }

    @include breakpoints('desktop') {
      display: flex;
      align-items: center;
      gap: 13px;
    }
  }
}
