@import '@quno/patient-journey/styles/breakpoints.scss';

.card {
  display: flex;
  align-items: center;
  gap: var(--spacing-4);
  flex-wrap: wrap;
}

.name {
  color: var(--color-ui-100);
  font-size: var(--text-base-size);
  font-weight: 700;
}

.description {
  font-size: var(--text-base-size);
}

.scheduleButton {
  width: 100%;

  button,
  button span {
    width: 100%;
  }

  svg {
    padding: 6px;
  }
}

@include breakpoints('desktop', 'tablet') {
  .scheduleButton {
    width: unset;
    flex: 1;
    justify-content: flex-end;
    display: flex;

    button,
    button span {
      width: unset;
    }
  }
}

.thumbnail {
  width: var(--spacing-13-5) !important;
  height: var(--spacing-12) !important;
  min-width: var(--spacing-13) !important;
  min-height: var(--spacing-13-5) !important;
  border: 1px solid #c5c8d2;
  border-radius: 50%;
  padding: var(--spacing-0-5);
}

.managerName {
  line-height: var(--line-height-5);
}
