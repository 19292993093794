@import '@quno/patient-journey/styles/breakpoints.scss';
@import '@quno/patient-journey/styles/variables.scss';

.title {
  max-width: $screens-xxl;
  margin: 0 auto;
  padding-top: 0;
  --title-color: var(--theme-dark-gray);
  color: var(--title-color) !important;
}

.left {
  text-align: left;
}

.center {
  text-align: center;
}

.container {
  padding: var(--spacing-14) var(--spacing-4) var(--spacing-12);
  position: relative;
  overflow: hidden;
  container-type: inline-size;

  @include breakpoints('desktop') {
    padding: var(--spacing-14);
  }
}

.fullContainer {
  @include breakpoints('desktop') {
    padding-inline: 0 !important;
  }
}

.narrowContainer {
  @include breakpoints('desktop') {
    padding-inline: 243px !important;
  }
}

.normalContainer {
  @include breakpoints('desktop') {
    padding: var(--spacing-14) !important;
  }
}

.swipeContainer {
  padding: var(--spacing-12) 0 var(--spacing-12);

  > .innerContainer > h2 {
    padding-inline: var(--theme-spacing-2);
    @include breakpoints('desktop') {
      padding-inline: 0;
    }

    @include breakpoints('tablet') {
      padding-inline: 0;
    }
  }

  .footer {
    max-width: $screens-xxl;
    margin: 0 auto;
    padding-inline: var(--theme-spacing-4);
  }
}

.innerContainer {
  position: relative;
  z-index: 1;
}

.backgroundLayer {
  background-size: cover;
  position: absolute;
  inset: 0;
  background-position: center;
}

.backgroundRepeat {
  background-repeat: repeat;
  background-size: initial;
}

.noBottomPadding {
  padding-bottom: 0;
}

.topPadding {
  padding-top: var(--spacing-8);
}

.bottomPadding {
  padding-bottom: var(--spacing-2);
}

.wrapper {
  display: flex;
  flex-wrap: wrap;
  margin: auto;
  flex-direction: column;
  max-width: $screens-xxl;
  gap: var(--spacing-4);
  position: relative;
  -ms-overflow-style: none; // for IE and Edge
  scrollbar-width: none; // for Firefox

  & > div {
    --gap: var(--spacing-4);
    flex-basis: calc(
      100% / var(--container-cols) - var(--gap) / var(--container-cols) *
        (var(--container-cols) - 1)
    );
  }

  &::-webkit-scrollbar {
    display: none; // for Chrome, Safari and Opera
  }

  > img {
    flex: 0;
    width: 100%;
    height: 80px;
    margin: auto;
  }

  @include breakpoints('desktop') {
    max-width: fit-content;
    flex-direction: row;
  }
}

.fullWrapper {
  max-width: 100% !important;
}

.narrowWrapper {
  max-width: calc($screens-xxl * 0.6) !important;
}

.normalWrapper {
  max-width: $screens-xxl !important;
}

.scrollable {
  flex-direction: row !important;
  padding-inline: 0 !important;

  @include breakpoints('phone') {
    display: flex;
    flex-wrap: nowrap;
    margin: auto;
    max-width: $screens-xxl;
    gap: var(--spacing-4);
    position: relative;
    scroll-snap-type: x mandatory;
    overflow-x: auto;
    overscroll-behavior-inline: contain;
    scroll-padding-inline: var(--theme-spacing-4);
    -ms-overflow-style: none; // for IE and Edge
    scrollbar-width: none; // for Firefox
    padding-inline: var(--spacing-4) !important;
    padding-top: var(--spacing-8);
    padding-bottom: var(--spacing-2);

    &::-webkit-scrollbar {
      display: none; // for Chrome, Safari and Opera
    }

    > img {
      flex: 0;
      width: 100%;
      height: 80px;
      margin: auto;
    }

    & > * {
      flex: 1;
      scroll-snap-align: start;
    }
  }
}
