@import '@quno/patient-journey/styles/breakpoints.scss';

.button {
  border-radius: var(--spacing-2);
  box-shadow: 0 1px var(--spacing-2) 0 #32485e29;
  font-size: var(--text-xl-size);
  font-weight: 700;
  padding: var(--spacing-4) 23px;
  width: 100%;

  @include breakpoints('desktop') {
    width: auto;
  }

  &:hover {
    filter: brightness(0.9);
  }
}

.buttonSecondary {
  background: transparent !important;
  font-size: var(--text-lg-size);
  font-weight: 700;
  padding: 0;
  width: 100%;
  text-align: center;

  @include breakpoints('desktop') {
    width: auto;
    text-align: left;
  }
}
