.darkBackground {
  background-color: var(--theme-background-color-dark);
}

.lightBackground {
  background-color: var(--theme-background-color-light);
}

.primary {
  background-color: var(--theme-primary-color);
}

.secondary {
  background-color: var(--theme-secondary-color);
}

.blurBackground {
  filter: blur(20px);
  transform: scale(1.1);
}

.darkMode > * {
  color: var(--theme-white) !important;
}

.lightMode > * {
  color: var(--theme-dark-gray) !important;
}
