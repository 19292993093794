@import '@quno/patient-journey/styles/breakpoints.scss';

.card {
  background: var(--color-white);
  border: 1px solid #e6e6e6;
  border-radius: var(--spacing-2-5);
}

.content {
  background: var(--color-white);
  height: auto;
  padding: var(--spacing-4);

  a {
    margin: auto;
  }
}

.footer {
  font-weight: 700;
}

.vertical {
  width: 100%;
  height: 306px;
  min-height: 306px;

  @include breakpoints('desktop') {
    height: 388px;
    min-height: 388px;
  }
}

.horizontal {
  width: 100%;
}

.responsive {
  height: 225px;
  min-height: 225px;
  width: 100%;

  @include breakpoints('desktop') {
    height: unset;
  }
}
