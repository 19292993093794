.alt {
  background: rgba(0, 0, 0, 0.3);
  color: var(--color-white);
  font-size: var(--text-sm-size);
  position: absolute;
  padding: var(--spacing-2-5);
  bottom: 0;
  right: 0;
}

.imageWrapper {
  position: relative;

  img {
    position: relative !important;
  }
}
