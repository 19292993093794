@import '@quno/patient-journey/styles/spacings.scss';
@import '@quno/patient-journey/styles/breakpoints.scss';

.information {
  border: 1px solid var(--theme-light-gray);
  background: #f8f7f7;
  border-radius: 8px;
  padding-block: $theme-spacing-2;
  width: 100%;
  text-align: center;

  p {
    font-size: var(--text-sm-size) !important;
    min-width: 100%;
  }

  b {
    font-size: var(--text-base-size);
    font-weight: 700;
  }

  .name {
    font-size: var(--text-lg-size);
  }

  .address {
    color: var(--theme-medium-gray);
  }

  .doctor {
    display: flex;
    flex-direction: column;
    padding-bottom: var(--spacing-2-5);
    padding-inline: $theme-spacing-2;

    img {
      border-radius: 50%;
      border: 1px solid var(--theme-light-gray);
      background: #fff;
      padding: 6px;
      margin: auto;
      margin-bottom: var(--spacing-2-5);
      width: 108px;
      height: 108px;
      object-fit: cover;
      object-position: top;
    }
  }

  .duration {
    display: flex;
    border-block: 1px solid var(--theme-light-gray);
    padding-block: $theme-spacing-1;

    .separator {
      width: 1px;
      background-color: var(--theme-light-gray);
    }

    .item {
      flex: 1;
      display: flex;
      align-items: center;
      justify-content: center;
      gap: var(--spacing-2);

      span {
        width: 100%;
      }
    }

    .item:first-of-type {
      padding-left: $theme-spacing-2;
      padding-right: $theme-spacing-1;
    }

    .item:last-of-type {
      padding-right: $theme-spacing-2;
      padding-left: $theme-spacing-1;
    }
  }

  .clinic {
    display: flex;
    flex-direction: column;
    padding-top: var(--spacing-2-5);
    gap: var(--spacing-2-5);
    padding-inline: $theme-spacing-2;
  }
}

@include breakpoints('desktop') {
  .information {
    max-width: 320px;
  }
}
