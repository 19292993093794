.date {
  font-size: 13px !important;
  margin-left: var(--spacing-8);
}

.item {
  min-width: 300px;

  p {
    font-size: var(--text-base-size) !important;
  }
}

.meta {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-block: var(--spacing-4);
  width: 100%;
}

.title {
  color: var(--theme-dark-gray);
  font-size: var(--text-xl-size) !important;
  margin-bottom: var(--spacing-2);
}

.customer {
  color: var(--color-siam);
  font-size: var(--text-base-size) !important;
  font-weight: 700;
  margin-bottom: var(--spacing-4);
}
