@import '@quno/patient-journey/styles/breakpoints.scss';

.arrow {
  svg {
    display: none;
    stroke: '#15202B';
    transition-property: transform;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    transition-duration: 200ms;
  }

  @include breakpoints('tablet', 'desktop') {
    svg {
      display: block;
    }
  }
}

.arrowOpen {
  svg {
    transform: rotate(180deg);
  }
}

.content {
  margin-top: 1.175rem;
}

.item {
  border: 1px solid var(--color-ui-50);
  border-radius: var(--spacing-2);
  cursor: pointer;
  margin: 0 auto var(--spacing-4);
  padding: 33px 40px;
}

.title {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: var(--text-xl-size) !important;
  font-weight: 700 !important;
  line-height: var(--line-height-normal);
  color: var(--color-ui-100) !important;
}
