@import '@quno/patient-journey/styles/breakpoints.scss';
@import '@quno/patient-journey/styles/spacings.scss';

.footer {
  position: relative;
  container-type: inline-size;
}

.footerContent {
  display: flex;
  gap: $theme-spacing-4;
  justify-content: space-between;
  padding: $theme-spacing-4;
  flex-direction: column;
  max-width: $container-max-width;
  margin: auto;
  position: relative;

  h4 {
    padding-bottom: $theme-spacing-1;
  }

  .socialLinks {
    padding-top: $theme-spacing-2;

    .socialLinkIcons {
      display: flex;
      flex-wrap: wrap;
      gap: $theme-spacing-4;

      img {
        width: auto;
        height: var(--spacing-9);
        object-fit: contain;
      }
    }
  }
}

.backgroundLayer {
  background-size: cover;
  position: absolute;
  inset: 0;
  margin-bottom: -5px;
}

.qunosuitePoweredBadgeWrapper {
  padding-bottom: var(--spacing-7-5);

  @include breakpoints('desktop') {
    justify-content: flex-start;
  }
}

.qunosuitePoweredBadge {
  display: inline-flex;
  background: var(--color-white);
  border-radius: var(--spacing-3-5);
  align-items: center;
  gap: var(--spacing-4);
  padding: var(--spacing-2);
  padding-right: var(--spacing-4);
  position: relative;

  span {
    color: var(--color-navy) !important;
  }
}

.qunosuitePoweredBadgeIcon {
  display: flex;
  background: var(--color-navy);
  border-radius: var(--spacing-3-5);
  align-items: center;
  justify-content: center;
  width: 65px;
  height: 65px;

  svg {
    transform: scale(1.5);
  }
}

.qunosuitePoweredBadgeText {
  display: flex;
  flex-direction: column;
  line-height: var(--line-height-7);

  span:first-of-type {
    font-size: var(--text-base-size) !important;
  }

  span:last-of-type {
    font-size: 25px !important;
    font-weight: 700;
  }
}

@include breakpoints('desktop') {
  .footerContent {
    flex-direction: row;

    .socialLinks {
      padding-top: 0;

      .socialLinkIcons {
        flex-wrap: nowrap;
      }
    }
  }
}
