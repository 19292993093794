@import '@quno/patient-journey/styles/breakpoints.scss';
@import '@quno/patient-journey/styles/spacings.scss';

.container {
  display: flex;
  justify-content: center;
  container-type: inline-size;
}

.customRichTextStyles {
  & ul li {
    p {
      padding-top: 0 !important;
    }
  }
}

.wrapper {
  display: flex;
  max-width: $container-max-width;
  width: 100%;
  flex-direction: column;
  padding: var(--spacing-14) 0;
  gap: 0;
}

.textSection {
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: $theme-spacing-1;
  padding: 0 var(--spacing-4);

  ol li {
    list-style: decimal;
  }

  ul li {
    list-style: disc;
  }
}

@include breakpoints('tablet', 'phone') {
  .wrapper {
    &.heroWithImage {
      padding-top: 0;
      gap: var(--spacing-4);
    }
  }

  .imageSection {
    order: 1;

    img {
      width: 100%;
    }
  }

  .textSection {
    order: 2;

    h2 {
      font-size: var(--text-3xl-size);
    }

    p {
      line-height: 27px;
    }
  }
}

.cardOnMobile {
  margin: var(--spacing-4);
  border-radius: var(--spacing-4);
  overflow: hidden;

  img {
    aspect-ratio: 1.7;
    max-height: 360px;
    object-fit: cover;
  }

  .wrapper {
    padding: 0;
  }

  .textSection {
    padding: 0 var(--spacing-4) var(--spacing-4);
  }

  @include breakpoints('desktop') {
    border-radius: 0;
    margin: 0;

    img {
      aspect-ratio: unset;
      max-height: unset;
    }

    .wrapper {
      padding: var(--spacing-14);
    }

    .textSection {
      padding: var(--spacing-4);
    }
  }
}

@include breakpoints('desktop') {
  .wrapper {
    flex-direction: row;
    gap: $theme-spacing-4;
    padding: var(--spacing-14);
  }

  .imageSection {
    max-width: 580px;
    overflow: hidden;
  }

  .textSection {
    padding: 0;

    h2 {
      font-size: 28px;
    }
  }
}

.ctas {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: var(--spacing-4);
}

.centerContent {
  justify-content: center;
  text-align: center;
}
