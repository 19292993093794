@import '@quno/patient-journey/styles/breakpoints.scss';

input {
  &:checked + .slider {
    background-color: var(--theme-primary-color);
    border-color: var(--color-black);
  }

  &:focus + .slider {
    box-shadow: 0 0 1px var(--theme-primary-color);
  }

  &:checked + .slider::before {
    transform: translateX(12px);
  }
}

.item {
  min-width: 306px;

  @include breakpoints('desktop') {
    min-width: auto !important;
  }
}

.hidden {
  display: none;
}

.media {
  inset: 0;
}

.image {
  position: absolute;
  inset: 0;
}

.switch {
  display: inline-block;
  position: absolute;
  height: var(--spacing-6);
  width: var(--spacing-9);
  bottom: 0;
  right: 0;

  & input {
    opacity: 0;
    height: 0;
    width: 0;
  }
}

.switchLabel {
  position: absolute;
  cursor: pointer;
  inset: 0;
  margin: var(--spacing-2);
}

.slider {
  background-color: var(--theme-light-gray);
  border: 1px solid transparent;
  border-radius: 34px;
  cursor: pointer;
  inset: 0;
  position: absolute;
  transition: 0.4s;

  &::before {
    background-color: var(--color-white);
    border-radius: 50%;
    position: absolute;
    content: ' ';
    height: 1.3rem;
    width: 1.3rem;
    left: 1px;
    bottom: 1px;
    transition: 0.4s;
  }
}
