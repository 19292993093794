@import '@quno/patient-journey/styles/breakpoints.scss';
@import '@quno/patient-journey/styles/spacings.scss';

.container {
  display: flex;
  justify-content: center;
  position: relative;
  overflow: hidden;
  container-type: inline-size;
}

.wrapper {
  display: flex;
  flex-direction: column;
  z-index: 100;
  width: 100%;
  max-width: $container-max-width;
}

.textContent {
  position: relative;
  margin-block: var(--spacing-4);

  --font-color: var(--color-black);

  & > *,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  p {
    color: var(--font-color) !important;
  }

  ol li {
    list-style: decimal;
  }

  ul li {
    list-style: disc;
  }
}

.backgroundLayer {
  position: absolute;
  width: 100%;
  height: 100%;
  background-size: cover;
  background-position: top center;
  left: 0;
  top: 0;
}

.backgroundRepeat {
  background-repeat: repeat;
  background-size: initial;
}

.textSection {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: $theme-spacing-1;
  padding: $theme-spacing-8 $theme-spacing-4;
  position: relative;
}

.funnelContainerClasses {
  border-radius: 0 !important;
}

.funnelWrapperClasses {
  > div {
    margin: 0 !important;
  }
}

.funnel {
  z-index: 10;
  position: relative;
}

.funnelContainer {
  position: relative;
}

.loadingFunnel {
  position: absolute;
  top: 0;
  background-color: var(--theme-white);
  height: 608px;
  padding: $theme-spacing-2;
  padding-top: var(--spacing-5);
  width: 100%;

  & span:nth-of-type(2) span {
    margin-top: var(--spacing-7-5);
    margin-bottom: var(--spacing-2);
  }

  & span:nth-of-type(4) span {
    margin-top: $theme-spacing-9;
  }
}

.funnel,
.funnelWrapperClasses {
  width: 100%;
  min-height: 608px;

  > div {
    max-width: 100% !important;
    border: unset !important;
  }
}

@container (min-width: 1024px) {
  .wrapper {
    flex-direction: row;
    gap: $theme-spacing-4;
    padding: $theme-spacing-4;
  }

  .textSection {
    min-height: unset;
    padding: 0;
    position: unset;
  }

  .funnelContainerClasses {
    border-radius: var(--spacing-4) !important;
  }

  .funnel,
  .funnelWrapperClasses {
    border-radius: var(--spacing-4) !important;
    width: 410px;
    overflow: hidden;
  }

  .loadingFunnel {
    border-radius: var(--spacing-4);
  }

  .backgroundLayer {
    height: 100% !important;
  }

  .funnelContainer {
    width: 410px;
  }
}

.ctas {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: var(--spacing-4);
  margin-top: var(--spacing-8);
}

.centerContent {
  justify-content: center;
  text-align: center;
}
