@import '@quno/patient-journey/styles/spacings.scss';
@import '@quno/patient-journey/styles/breakpoints.scss';

.wrapper {
  padding: 0;
}

.container {
  background-color: var(--theme-white);
  margin: auto;
  max-width: 1260px;
  overflow: hidden;

  &.containerWithBorder {
    padding: $theme-spacing-3 $theme-spacing-2;
    border: 1px solid var(--theme-light-gray);
    border-radius: var(--spacing-2);
    box-shadow: 0 var(--spacing-1) var(--spacing-3) 0 rgba(0, 0, 0, 0.1);
  }

  h1 {
    font-size: 28px;
    color: var(--theme-dark-gray);
    font-weight: 700;
    padding: 0;
  }
}

.section {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding-top: var(--spacing-6-5);
  gap: $theme-spacing-2;
}

.overlay {
  top: 0;
  left: 0;
  position: absolute;
  border-radius: var(--spacing-2);
  width: 100%;
  height: 100%;
  opacity: 0.4;
  display: flex;
  z-index: 100;
  background-color: var(--color-white);
  cursor: wait;

  > div {
    width: var(--spacing-12) !important;
    height: var(--spacing-12) !important;
    margin: auto;
  }
}

.calendar {
  position: relative;
  width: 100%;
  min-height: 386px;
  margin-top: var(--spacing-5);

  @include breakpoints('desktop') {
    margin-top: 0;
  }
}

@include breakpoints('desktop') {
  .wrapper {
    padding: $theme-spacing-2 $theme-spacing-4;
  }

  .section {
    flex-direction: row;
  }

  .calendar {
    min-height: auto;
  }
}

.markdown {
  white-space: pre-wrap;
  display: flex;
  flex-direction: column;
  gap: var(--spacing-4);
  color: black;
}
