.item {
  min-width: 300px;

  & > div {
    gap: var(--spacing-5-5);
    height: 100%;
  }

  .imageWrapper {
    width: 100%;
    height: var(--spacing-80);

    img {
      object-fit: cover;
      border-radius: var(--spacing-2);
    }
  }

  .title {
    font-size: var(--text-2xl-size);
    margin-bottom: var(--spacing-2);
  }

  .duration {
    display: flex;
    padding: var(--spacing-2) var(--spacing-3);
    gap: var(--spacing-2-5);
    background-color: var(--theme-light-gray);
    border-radius: var(--spacing-5);
    width: fit-content;
  }

  .description {
    font-size: var(--text-lg-size);
  }

  .footer {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: auto;

    .price {
      font-size: 28px;
      font-weight: 700;
      line-height: 150%;
    }
  }
}
